import React from 'react';
import { NavLink } from "react-router-dom";
import './ProductCard.css'; 

const ProductCard = ({ id, name, image, category }) => {
  return (
    <NavLink to={`singleProduct/${id}`} className="grid-nav-link">
      <div className='grid-box'>
        <div className="mb-4 grid-card">
          <img src={image} className="card-img-top grid-img-top" alt={name} />
          <div className="grid-card-body">
            <div className="grid-caption">{category}</div>
            <h5 className="grid-card-title">{name}</h5>
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default ProductCard;
// <NavLink to={`singleProduct/${id}`} className="grid-nav-link"></NavLink>