// Footer.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';
import "bootstrap-icons/font/bootstrap-icons.css";

const Footer = () => {
  return (
    <footer id="footer">
        <div class="container-fluid footer-top" style={{"backgroundColor": "#37517e", "color": "white"}}>
        <div class="row ">
            <div class="col-lg-6 col-md-6 sm-12 p-5 ">
            <div class="row" style={{"margin-left": "3rem"}}>
                <h2 style={{"margin-bottom": "2rem"}}>Our Services</h2>
                <div class="col-lg-12 col-md-12 footer-links">

                <ul>
                    <li> <a href="./solutions/vision_ai.html"><i class="bx bx-chevron-right"></i>Vision AI</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="./solutions/voice_ai.html">Voice AI</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="./solutions/text_ai.html">Text AI</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="./solutions/generative_ai.html">Generative AI</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="./solutions/automation.html">Automation</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="./solutions/data_intelligence.html">Data
                        Intelligence</a></li>
                </ul>
                </div>

            </div>
            </div>
            <div class="col-lg-6 col-md-6 sm-12 p-5">
            <div class="d-flex flex-column" style={{"width": "80%"}}>
                <h2>Contact Us</h2>
                <form id="fs-frm" name="simple-contact-form" accept-charset="utf-8" action="https://formspree.io/f/moqobbry"
                method="post">
                <div style={{"margin-left": "12px"}}>
                    <div class="row mb-3 d-flex justify-content-between">
                        <input class="col-6 border rounded-3 p-2" style={{"width": "49%"}} type="text" name="name" id="full-name"
                            placeholder="Name" required="" />
                        <input class="col-6 border rounded-3 p-2" style={{"width": "49%"}} type="tel" name="phoneNumber" id="phone"
                            placeholder="Contact No.(Optional)" />
                    </div>
                    <div class="row mb-3">
                        <input class="col-12 border rounded-3 p-2" type="email" name="_replyto" id="email-address"
                            placeholder="Email" required=""/>
                    </div>
                    <div class="row mb-3">
                    <textarea class="col-12 border rounded-3 p-2" rows="3" name="message" id="message"
                        placeholder="Enter your message" required=""></textarea>
                    </div>
                </div>
                <button class="btn" style={{"backgroundColor": "#47b2e4", "width": "7rem"}} type="submit">Submit</button>
                </form>

            </div>
            </div>
        </div>
        <div class="container footer-bottom clearfix">
            <div class="copyright">
            &copy; Copyright <strong><span>SixthSensAI 2024 (reg as Aabhas Gurukuls LLP)</span></strong>. All Rights
            Reserved
            </div>
        </div>
        </div>
    </footer>
  );
};

export default Footer;
