import { createContext, useContext, useEffect, useReducer } from "react";
import reducer from "../reducer/productReducer";
import facial_recognition from '../marketplace-img/facial_recognition.webp';
import abandoned_object from '../marketplace-img/abandoned_object.webp';
import data_parser from '../marketplace-img/data_parser.webp';
import data_scraper from '../marketplace-img/data_scraper.webp';
import drowsiness_detection from '../marketplace-img/drowsiness_detection.webp';
import human_fall_detection from '../marketplace-img/human_fall_detection.webp';
import invoice_text_extractor from '../marketplace-img/invoice_text_extractor.webp';
import license_plate from '../marketplace-img/license_plate.webp';
import menopause_kit_result from '../marketplace-img/menopause_kit_result.webp';
import NLP_text_analysis from '../marketplace-img/NLP_text_analysis.webp';
import NLU_voicechat from '../marketplace-img/NLU_voicechat.webp';
import people_monitoring from '../marketplace-img/people_monitoring.webp';
import proctoring_exam from '../marketplace-img/proctoring_exam.webp';
import question_answering from '../marketplace-img/question_answering.webp';
import recommendation_system from '../marketplace-img/recommendation_system.webp';
import safety_gears from '../marketplace-img/safety_gears.webp';
import sales_forecasting from '../marketplace-img/sales_forecasting.webp';
import speed_monitoring from '../marketplace-img/speed_monitoring.webp';
import video_management_system from '../marketplace-img/video_management_system.webp';
import visual_question_answering from '../marketplace-img/visual_question_answering.webp';

const API = [
{
  "id": "FR-SPOOF",
  "name": "Facial Recognition & Liveness Detection",
  "company": "Security",
  "onelinerdescription": "Cutting-edge facial recognition and liveness detection system for enhanced security measures.",
  "image": facial_recognition,
  "description": (
    <div>
      <h3>Project Overview:</h3>
      <p>
        Our Facial Recognition & Liveness Detection project is at the forefront of security technology, offering advanced capabilities in facial recognition and liveness detection.
      </p>
      <br /><br />

      <h3>Advanced Features:</h3>
      <ul>
        <li>
          <p>Facial Recognition: The system utilizes cutting-edge algorithms to perform accurate facial recognition, requiring only one image for identification across multiple images.</p>
        </li>
        <li>
          <p>Liveness Detection: Live inference enables the system to detect liveness in real-time, ensuring that the subject is live and present during identification.</p>
        </li>
        <li>
          <p>Custom Threshold Adjustment: Users have the flexibility to adjust model thresholds, especially for spoof detection, ensuring robust security measures.</p>
        </li>
        <li>
          <p>Real-time Inference: The system performs live inference, providing instantaneous results for both facial recognition and liveness detection.</p>
        </li>
      </ul>
      <br /><br />

      <h3>Key Capabilities:</h3>
      <ul>
        <li>
          <p>Facial Recognition with Minimal Data: Achieve accurate identification with minimal data input, streamlining the recognition process.</p>
        </li>
        <li>
          <p>Enhanced Security Measures: Robust liveness detection and threshold adjustment options bolster security protocols against spoofing attempts.</p>
        </li>
        <li>
          <p>Real-time Live Inference: Instantaneous identification and liveness verification ensure timely and secure access control.</p>
        </li>
      </ul>
      <br /><br />

      <h3>Application:</h3>
      <p>
        Our system finds extensive application in security domains, including access control, authentication systems, and surveillance for heightened security measures.
      </p>
    </div>
  ),
  "category": "Video",
  "embed": "<iframe width=\"914\" height=\"514\" src=\"https://www.youtube.com/embed/S3zwUxtWQKg\" title=\"Facial Recognition   Liveliness Detection Demo\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
  "link": "https://sixthsens-fr-spoof.streamlit.app/"
},
  {
    "id": "NLU-VoiceBot",
    "name": "NLU VoiceBot",
    "company": "Healthcare",
      "onelinerdescription": "An intelligent Hindi Voice Bot for electricity-related inquiries, providing quick and accurate information.",

    "image": NLU_voicechat,
"description": (
    <div>
      <h3>App Description:</h3>    <br/>

      <p>
        Our Hindi Voice Bot is expertly tailored to assist customers seeking help with their electricity-related inquiries on the Rajasthan Electricity Board's website. Seamlessly integrated with voice capabilities, this intelligent bot is your go-to source for quick and accurate information.
      </p>
      <br />    <br/>


      <h3>Use Case and Commands:</h3>    <br/>

      <p>
        This app is perfectly aligned with the needs of the Electricity Board's customer helpdesk. Users can engage the bot by employing the following sample commands:
      </p>    <br/>

      <ol type="1">
        <li>
          <p>नए बिजली का अकाउंट खुलवाना है</p>
        </li>
        <li>
          <p>बिजली का नया कनेक्शन चाहिए क्या करना होगा</p>
        </li>
        <li>
          <p>कौन से दस्तावेज जमा करने हैं</p>
        </li>
        <li>
          <p>कौन से डॉक्यूमेंट सबमिट करने हैं</p>
        </li>
        <li>
          <p>नए कनेक्शन के लिए कितनी फीस जमा करनी है</p>
        </li>
        <li>
          <p>बिजली कनेक्शन का शुल्क कितना है</p>
        </li>
        <li>
          <p>कनेक्शन की आखिरी तिथि बढ़ानी है</p>
        </li>
        <li>
          <p>कनेक्शन खत्म हो गया है| वापस कैसे एक्टिवेट करें</p>
        </li>
      </ol>    <br/>

      <p>Please note that queries unrelated to the electricity board's scope will fall outside the bot's capabilities, such as:</p>
      <ul>    <br/>

        <li>
          <p>मुझे खाना ऑर्डर करना है</p>
        </li>
        <li>
          <p>यूएस का प्रेसिडेंट कौन है</p>
        </li>
      </ul>
      <br />    <br/>


      <h3>Bot Technology:</h3>    <br/>

      <p>
        Our Hindi Voice Bot is powered by a sophisticated machine learning framework. Through natural language understanding (NLU), the bot comprehends user prompts and provides accurate responses using pre-fed intents. With the integration of voice features, customers can interact with the bot effortlessly and receive prompt assistance.
      </p>
      <br />    <br/>


      <h3>Trained Intents:</h3>    <br/>

      <ol type="A">
        <li>
          <p>Apply for connection:</p>
          <ol>
            <li>
              <p>(a) नया कनेक्शन चाहिए</p>
            </li>
            <li>
              <p>(b) नया बिजली का अकाउंट खुलवाना है, क्या करना होगा</p>
            </li>
          </ol>
        </li>
        <li>
          <p>Documents Required:</p>
          <ol>
            <li>
              <p>(a) कौन से दस्तावेज जमा करने हैं</p>
            </li>
            <li>
              <p>(b) क्या क्या दस्तावेज चाहिए होंगे</p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  ),    "category": "Audio",
    "embed": "<iframe width=\"930\" height=\"523\" src=\"https://www.youtube.com/embed/ZOUnt6ZOJDE\" title=\"Hindi VoiceBot Demo\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
      "link": "https://sixthsens-voicebot.netlify.app/"

},
  {
  "id": "qa",
  "name": "Question Answering",
  "company": "Education",
  "onelinerdescription": "Advanced Question Answering using Elasticsearch with custom document upload support.",
  "image": question_answering,
  "description": (
    <div>
      <h3>Product Overview:</h3><br />
      <p>
        The Elasticsearch Question Answering module is a robust solution that leverages the power of Elasticsearch to provide advanced question answering capabilities. It goes beyond traditional QA systems by allowing users to upload and index documents in PDF format, enabling efficient and context-aware responses to user queries.
      </p>
      <br /><br />
      <h3>Key Features:</h3><br />
      <ul>
        <li>
          <p>Document Upload: Users can effortlessly upload single or multiple documents in PDF format, expanding the knowledge base for accurate question answering.</p>
        </li>
        <li>
          <p>Elasticsearch Integration: The module seamlessly integrates with Elasticsearch, utilizing its powerful indexing and search capabilities to retrieve relevant information from uploaded documents.</p>
        </li>
        <li>
          <p>Context-Aware Responses: By indexing the content of uploaded documents, the system provides context-aware responses to user queries, enhancing the overall accuracy and relevance of answers.</p>
        </li>
        <li>
          <p>User-Friendly Interface: The user interface is designed for simplicity, allowing users to easily upload, manage, and query documents for quick and precise answers.</p>
        </li>
      </ul>
      <br /><br />

    </div>
  ),
  "category": "Text",
  "embed": "<iframe width=\"930\" height=\"523\" src=\"https://www.youtube.com/embed/ZCHqoX8KX1c\" title=\"Custom Document Question Answering Demo\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
        "reachoutmessage": "For QA Demo Please Reach Out to shubham@sixthsens.ai"
},
  {
    "id": "proctor",
    "name": "E-Exam Proctoring System",
    "company": "Education",
      "onelinerdescription": "Real-time proctoring system with features like face detection, cheat estimation, and post-proctoring analysis.",

    "image": proctoring_exam,
  "description": (
    <div>
      <h3>Objectives:</h3><br />
      <p>
        Develop a real-time proctoring system to replace human proctoring during tests.
        Establish a remote candidate login system for online exams.
        Detect identity fraud and analyze cheating behavior.
        Monitor candidates through live streaming.
        Implement post-proctoring analysis, including face detection status, cheat estimation, user authentication, and log analysis.
      </p>
      <br /><br />
      <h3>Current Features:</h3><br />
      <p>
        The Proctor Software, powered by a combination of Mediapipe, Inception Resnet V2, and Django, ensures a secure online exam environment with the following features:
      </p>
      <ol type="A">
        <li>
          <p>Face Detection and Recognition: Utilizing Mediapipe for face detection and Inception Resnet V2 for face recognition, the system verifies users' identities during exams. It detects instances of no face or multiple faces, indicating potential cheating.</p>
        </li>
        <li>
          <p>Cheat Detection: Matplotlib generates estimated cheat reports based on users' cheat statuses during the exam.</p>
        </li>
        <li>
          <p>Online Exam Management: Django facilitates an online exam management interface where students log in for exams, authenticated by the face recognition model. An admin panel is provided for exam and student management, along with viewing cheat reports.</p>
        </li>
      </ol>
      <br /><br />
      <h3>Additional Features:</h3><br />
      <p>
        Following the initial paper publication, the Proctor Software gained advanced capabilities:
      </p>
      <ol type="A">
        <li>
          <p>Eye Movement Tracking: Utilizing Mediapipe, the system tracks students' eye movements to detect when they look away from the screen or at another part, potentially indicating cheating behaviors.</p>
        </li>
        <li>
          <p>Mouth Detection: Mediapipe detects mouth movements to identify instances of talking or whispering during the exam, helping to uncover cheating incidents.</p>
        </li>
        <li>
          <p>Head Pose Estimation: Mediapipe estimates students' head poses to detect when they divert their gaze from the screen, providing insights into potential cheating actions.</p>
        </li>
        <li>
          <p>Face Spoofing Detection: By employing OpenCV, the system identifies face spoofing attempts, such as using fake faces or photographs instead of real faces.</p>
        </li>
      </ol>
      <br /><br />
      <p>
        These enhancements fortify the Proctor Software's capabilities in maintaining exam integrity, safeguarding against fraudulent behaviors, and ensuring a fair testing environment.
      </p>
      <br /><br />
      <h3>Access the Published Paper Here:
      <a href="https://link.springer.com/chapter/10.1007/978-3-031-25344-7_34" target="_blank" rel="noopener noreferrer"> Published Paper Link</a></h3>
    </div>
  ),
    "category": "Video",
    "embed": "<iframe width=\"930\" height=\"523\" src=\"https://www.youtube.com/embed/nSA9gMuKTSM\" title=\"proctor demo\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
    "reachoutmessage": "For Proctor Demo Please Reach Out to shubham@sixthsens.ai"
},
  {
  "id": "nlp-text-analysis",
  "name": "NLP Text Analysis",
  "company": "Education",
  "onelinerdescription": "Empowering text analysis with Natural Language Processing (NLP) for various applications.",
  "image": NLP_text_analysis,
  "description": (
    <div>
      <h3>Project Overview:</h3>
      <p>
        The NLP Text Analysis project harnesses the capabilities of Natural Language Processing to offer a range of text analysis options for diverse applications. From discerning sentiments to identifying offensive content, our advanced NLP algorithms provide valuable insights from textual data.
      </p>
      <br /><br />

      <h3>Text Analysis Options:</h3>
      <ol>
        <li>
          <p>Spam or Ham Detection</p>
        </li>
        <li>
          <p>Sentiment Analysis</p>
        </li>
        <li>
          <p>Stress Detection</p>
        </li>
        <li>
          <p>Hate and Offensive Content Detection</p>
        </li>
        <li>
          <p>Sarcasm Detection</p>
        </li>
      </ol>
      <br /><br />

      <h3>Key Features:</h3>
      <ul>
        <li>
          <p>Robust NLP Algorithms: Our system employs state-of-the-art NLP algorithms for accurate and efficient text analysis.</p>
        </li>
        <li>
          <p>Real-time Analysis: Enjoy real-time analysis capabilities for immediate insights into textual content.</p>
        </li>
        <li>
          <p>Customizable Options: Tailor the analysis based on your specific needs, whether it's for social media monitoring or business communication evaluation.</p>
        </li>
        <li>
          <p>User-Friendly Interface: The user interface is designed for ease of use, allowing users to interact with and interpret the analysis results effortlessly.</p>
        </li>
      </ul>
      <br /><br />

      <h3>Use Cases:</h3>
      <p>
        The NLP Text Analysis project finds application in various domains, including social media monitoring, customer feedback analysis, and content moderation.
      </p>
    </div>
  ),
  "category": "Text",
  "embed": "<iframe width=\"930\" height=\"523\" src=\"https://www.youtube.com/embed/UQzd8i99sq0\" title=\"NLP Text Analysis   SPAM, Sentiment Analysis, STRESS, HATE Detection - DEMO VIDEO SIXTHSENS AI\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
  "link": "https://nlp-text-analysis-sixthsens.streamlit.app/"
},
  {
    "id": "ppe",
    "name": "Safety Gears Detection",
    "company": "Security",
      "onelinerdescription": "Vision AI application for detecting safety gear compliance through image and video analysis.",

    "image": safety_gears,
  "description": (
    <div>

      <h3>About Project:</h3><br />
      <p>
        <strong>Goal:</strong> The project aimed to develop an application for a prominent telecommunications company in India. The application utilized Vision AI technology to detect safety gear worn by workers, such as helmets/hardhats and safety vests. It enabled the analysis of images and real-time video captured through mobile app cameras or CCTV feeds, ensuring safety compliance during attendance marking.
      </p>
      <br /><br />

      <h3>Application Workflow:</h3><br />
      <ol>
        <li>
          <p><strong>Image Upload:</strong> The user uploads an image to the application for safety gear detection.</p>
        </li>
        <li>
          <p><strong>Safety Gear Detection:</strong> The uploaded image is processed using the pre-trained model for fall detection of a person, hardhat, and vest.</p>
        </li>
        <li>
          <p><strong>Display of Results:</strong> The processed image is displayed with bounding boxes around the detected safety gear areas, such as the person, hardhat, and vest.</p>
        </li>
        <li>
          <p><strong>Review and Verification:</strong> The user can review the processed image and verify if the safety gears have been accurately detected.</p>
        </li>
      </ol>
      <br />
    </div>
  ),
    "category": "Image",
    "embed": "<iframe width=\"973\" height=\"523\" src=\"https://www.youtube.com/embed/3CT-DLsB-oY\" title=\"Helmet Vest Hardhat Detection Demo\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
    "link": "https://sixthsens-safety-gears-detection.streamlit.app/"
},
  {
    "id": "peoplemonitoring",
    "name": "People Monitoring",
    "company": "Security",
      "onelinerdescription": "Cutting-edge solution for real-time counting, monitoring, and alerting of individuals entering and exiting premises.",

    "image": people_monitoring,
  "description": (
    <div>
      <h3>Project Overview:</h3><br />
      <p>
        People Monitoring is a cutting-edge security solution that provides real-time counting and monitoring of individuals entering and exiting a premises. This advanced system not only tracks foot traffic but also includes intelligent alerting features when a predefined threshold of people is exceeded in a specific area.
      </p>
      <br /><br />
      <h3>Key Features:</h3><br />
      <ul>
        <li>
          <p>Accurate People Counting: People Monitoring employs state-of-the-art technology to accurately count and record the number of individuals entering and leaving a designated space. This information is invaluable for security and operational purposes.</p>
        </li>
        <li>
          <p>Real-time Alerts: The system offers real-time alerting capabilities, sending notifications when the count in a specific area surpasses the predefined limit. This helps security personnel take immediate action when necessary.</p>
        </li>
        <li>
          <p>Video Documentation: Every entry and exit is documented through video footage, enabling comprehensive monitoring and incident review, should the need arise.</p>
        </li>
        <li>
          <p>Integration: People Monitoring is designed for easy integration with existing security and access control systems, enhancing the overall security infrastructure of a facility.</p>
      </li></ul>
      <br /><br />
      <h3>Use Cases:</h3><br />
      <p>
        People Monitoring is a versatile solution with applications in various sectors, including retail, hospitality, public venues, and more. It is an ideal choice for security enhancement, crowd control, and maintaining a safe environment for both customers and employees.
      </p>
    </div>
  ),
    "category": "Video",
    "embed": "<iframe width=\"930\" height=\"523\" src=\"https://www.youtube.com/embed/J309O2Lh3p0\" title=\"People Monitoring\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
      "link": "https://sixthsens-people-monitoring.streamlit.app/"

},
  {
    "id": "AbandonedObjectDetection",
    "name": "Abandoned Object Detection",
    "company": "Security",
      "onelinerdescription": "Security system identifying and reporting unattended objects in real-time, enhancing situational awareness.",

    "image": abandoned_object,
"description": (
    <div>
      <h3>Project Overview:</h3><br />
      <p>
        Abandoned Object Detection is a sophisticated security solution designed to identify and flag unattended objects or obstructions in pathways. This innovative system not only detects abandoned objects but also calculates and reports the area occupied by the object in relation to the camera's perspective.
      </p>
      <br /><br />
      <h3>Key Features:</h3><br />
      <ul>
        <li>
          <p>Object Detection: The project employs advanced computer vision techniques to recognize and track objects that have been left unattended or are obstructing walkways, entrances, or critical areas.</p>
        </li>
        <li>
          <p>Area Calculation: Abandoned Object Detection provides the precise area measurements of the identified objects relative to the camera, enhancing situational awareness for security and operational purposes.</p>
        </li>
        <li>
          <p>Real-time Alerts: The system delivers real-time alerts to security personnel when an abandoned object is detected, allowing for quick response and resolution of potential security concerns.</p>
        </li>
        <li>
          <p>Integration: This solution seamlessly integrates with existing security and surveillance systems, bolstering the overall safety and efficiency of a facility or public space.</p>
      </li>
        </ul>

      <br /><br />
      <h3>Use Cases:</h3><br />
      <p>
        Abandoned Object Detection finds application in a variety of settings, including transportation hubs, airports, shopping centers, and critical infrastructure. It is an invaluable tool for enhancing security and ensuring the unimpeded flow of people and vehicles while minimizing potential threats.
      </p>
    </div>
  ),    "category": "Video",
    "embed": "<iframe width=\"930\" height=\"523\" src=\"https://www.youtube.com/embed/XTCCTZtdEVI?si=8KEgTLV3ycpn6ekn\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
    "link": "https://sixthsens-abandoned-object-detection.streamlit.app/"

},
  {
    "id": "ANPR",
    "name": "Automatic Number Plate Detection",
    "company": "Transport",
      "onelinerdescription": "Advanced license plate recognition for automatic detection and text extraction, aiding traffic monitoring and law enforcement.",

    "image": license_plate,
  "description": (
    <div>
      <h3>Project Overview:</h3><br />
      <p>
        License Plate Recognition is an advanced system that automates the detection of license plates on vehicles and extracts the text imprinted on them. This powerful solution finds applications in various domains, including traffic monitoring, vehicle tracking, and law enforcement.
      </p>
      <br /><br />
      <h3>Key Features:</h3><br />
      <ul>
        <li>
          <p>Automatic License Plate Detection: The project utilizes state-of-the-art computer vision algorithms to automatically identify and locate license plates on vehicles, even in challenging conditions.</p>
        </li>
        <li>
          <p>Text Extraction: It reads and extracts the text from the license plates, providing valuable data for various applications such as toll collection, parking management, and vehicle access control.</p>
        </li>
        <li>
          <p>Real-time Insights: License Plate Recognition offers real-time insights into vehicle movement, enabling traffic monitoring, security surveillance, and law enforcement agencies to make informed decisions swiftly.</p>
        </li>
        <li>
          <p>Integration: The system seamlessly integrates with existing traffic management and surveillance infrastructure, enhancing its capabilities and functionality.</p>
      </li></ul>
      <br /><br />
      <h3>Use Cases:</h3><br />
      <p>
        License Plate Recognition has a wide range of applications, from monitoring and controlling traffic flow to enhancing security and public safety. It is a versatile tool that aids in identifying vehicles, tracking their movements, and managing parking facilities efficiently.
      </p>
    </div>
  ),    "category": "Image",
    "embed": "<iframe width=\"930\" height=\"523\" src=\"https://www.youtube.com/embed/oNkZkPqh0VQ\" title=\"Automatic Number Plate Recognition\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
    "link": "https://sixthsens-autolpr.streamlit.app/"
},
  {
    "id": "speed",
    "name": "Speed Monitoring of Vehicles",
    "company": "Transport",
      "onelinerdescription": "Real-time monitoring of vehicle speed with intelligent alerting features for enhanced traffic control.",

    "image": speed_monitoring,
"description": (
    <div>
      <h3>Project Overview:</h3><br />
      <p>
        People Monitoring is a cutting-edge security solution that provides real-time counting and monitoring of individuals entering and exiting a premises. This advanced system not only tracks foot traffic but also includes intelligent alerting features when a predefined threshold of people is exceeded in a specific area.
      </p>
      <br /><br />
      <h3>Key Features:</h3><br />
      <ul>
        <li>
          <p>Accurate People Counting: People Monitoring employs state-of-the-art technology to accurately count and record the number of individuals entering and leaving a designated space. This information is invaluable for security and operational purposes.</p>
        </li>
        <li>
          <p>Real-time Alerts: The system offers real-time alerting capabilities, sending notifications when the count in a specific area surpasses the predefined limit. This helps security personnel take immediate action when necessary.</p>
        </li>
        <li>
          <p>Video Documentation: Every entry and exit is documented through video footage, enabling comprehensive monitoring and incident review, should the need arise.</p>
        </li>
        <li>
          <p>Integration: People Monitoring is designed for easy integration with existing security and access control systems, enhancing the overall security infrastructure of a facility.</p>
        </li></ul>
      <br /><br />
      <h3>Use Cases:</h3><br />
      <p>
        People Monitoring is a versatile solution with applications in various sectors, including retail, hospitality, public venues, and more. It is an ideal choice for security enhancement, crowd control, and maintaining a safe environment for both customers and employees.
      </p>
    </div>
  ),
"category": "Video",
    "embed": "<iframe width=\"973\" height=\"523\" src=\"https://www.youtube.com/embed/d91BdM33Tn0\" title=\"Vehicle Speed monitoring\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
    "link": "https://sixthsens-traffic-monitoring.streamlit.app/"
},
  {
    "id": "falldetection",
    "name": "Human Fall Detection",
    "company": "Healthcare",
      "onelinerdescription": "Healthcare solution utilizing computer vision for automated detection of human falls with real-time alerts.",

    "image": human_fall_detection,
"description": (
    <div>
      <h3>Project Overview:</h3><br />
      <p>
        License Plate Recognition is an advanced system that automates the detection of license plates on vehicles and extracts the text imprinted on them. This powerful solution finds applications in various domains, including traffic monitoring, vehicle tracking, and law enforcement.
      </p>
      <br /><br />
      <h3>Key Features:</h3><br />
      <ul>
        <li>
          <p>Automatic License Plate Detection: The project utilizes state-of-the-art computer vision algorithms to automatically identify and locate license plates on vehicles, even in challenging conditions.</p>
        </li>
        <li>
          <p>Text Extraction: It reads and extracts the text from the license plates, providing valuable data for various applications such as toll collection, parking management, and vehicle access control.</p>
        </li>
        <li>
          <p>Real-time Insights: License Plate Recognition offers real-time insights into vehicle movement, enabling traffic monitoring, security surveillance, and law enforcement agencies to make informed decisions swiftly.</p>
        </li>
        <li>
          <p>Integration: The system seamlessly integrates with existing traffic management and surveillance infrastructure, enhancing its capabilities and functionality.</p>
      </li></ul>
      <br /><br />
      <h3>Use Cases:</h3><br />
      <p>
        License Plate Recognition has a wide range of applications, from monitoring and controlling traffic flow to enhancing security and public safety. It is a versatile tool that aids in identifying vehicles, tracking their movements, and managing parking facilities efficiently.
      </p>
    </div>
  ),    "category": "Video",
    "embed": "<iframe width=\"930\" height=\"523\" src=\"https://www.youtube.com/embed/7syuWJxRcZo\" title=\"Human Fall Detection\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
},
  {
  "id": "menopause",
  "name": "Menopause Kit Result Classification",
  "company": "Healthcare",
  "image": menopause_kit_result,
  "onelinerdescription": "Automated classification system for early menopause detection based on kit test line analysis.",
  "description": (
    <div>
      <h3>Project Overview:</h3>
      <p>
        The Menopause Kit Result Classification project focuses on automating the analysis of early menopause detection kits. It aims to accurately interpret the test results by detecting and classifying the number of lines present on the kit.
      </p>
      <br /><br />

      <h3>Detection Methodology:</h3>
      <p>
        The system employs image processing techniques to identify and classify the number of lines displayed on the menopause detection kit. It recognizes:
      </p>
      <ul>
        <li>
          <p>One Line: Indicates a negative result for menopause detection.</p>
        </li>
        <li>
          <p>Two Lines: Signifies a positive result for early menopause detection.</p>
        </li>
        <li>
          <p>Additional Cases: Handling of cases involving unclear or unusual line patterns for further analysis or manual review.</p>
        </li>
      </ul>
      <br /><br />

      <h3>Key Features:</h3>
      <ul>
        <li>
          <p>Line Detection Algorithm: Utilizes computer vision algorithms to precisely identify and classify the number of lines on the menopause kit.</p>
        </li>
        <li>
          <p>Automated Interpretation: Provides automated classification of results based on the detected lines, minimizing manual intervention.</p>
        </li>
        <li>
          <p>Reporting and Analysis: Generates reports for detected results and handles edge cases for further analysis or review by healthcare professionals.</p>
        </li>
      </ul>
      <br /><br />

      <h3>Application:</h3>
      <p>
        This classification system finds application in healthcare settings, specifically aiding in early menopause detection. It streamlines the interpretation process, allowing for timely and accurate results.
      </p>
    </div>
  ),
  "category": "Image",
  "link": "https://menopause-sixthsens.streamlit.app/",
    "embed": "<iframe width=\"914\" height=\"514\" src=\"https://www.youtube.com/embed/kCiLsg1keTo\" title=\"Menopause Kit Result Detection Demo\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
},
  {
    "id": "ITE",
    "name": "Invoice Text Extractor",
    "company": "Finance",
      "onelinerdescription": "Revolutionizing invoice processing with real-time data extraction for enhanced operational efficiency.",
    "image": invoice_text_extractor,
    description: (
  <div>
    <h3>Benefit:</h3>
    <br/>
    <p>
      This innovative automation revolutionizes traditional manual invoice
      processing by condensing months' worth of human effort into a
      streamlined overnight process. Through the implementation of our
      cutting-edge built model, businesses can now effortlessly extract
      crucial data from invoices in real-time, significantly enhancing their
      operational efficiency.
    </p>    <br/>
    <br/>

    <h3>Solution Brief:</h3>    <br/>

    <p>
      Our state-of-the-art Machine-Vision/AI Model has been meticulously
      engineered to decipher invoices in both image and PDF formats. By
      seamlessly identifying and extracting essential fields such as
      Invoice Number, GST Number, Seller/Buyer Details, Total Amount, and
      more, our solution eliminates the arduous manual data entry process.
    </p>    <br/>
    <br/>

    <h3>Technology Used:</h3>    <br/>

    <ul>
      <li>
        <p>Machine Vision: Our advanced Machine Vision capabilities empower the
          system to interpret and analyze invoice visuals with remarkable
          accuracy.
        </p>
      </li>    <br/>

      <li>
        <p>NLP (Natural Language Processing): Through NLP, the application
          processes and comprehends text-based content on invoices, enabling
          comprehensive data extraction.
        </p>
      </li>    <br/>

      <li>
        <p>Machine Learning: The incorporation of Machine Learning techniques
          ensures the system continuously improves its accuracy and
          adaptability over time.
        </p>
      </li>    <br/>    <br/>


    </ul>

  </div>
),
    "category": "Text",
    "link": "https://sixthsens-invoice-text-extractor.streamlit.app/",
        "embed": "<iframe width=\"930\" height=\"523\" src=\"https://www.youtube.com/embed/9DsVV58YsY8\" title=\"Automated Invoice Text Extractor Demo\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
},
  {
  "id": "vms",
  "name": "Video Management System (VMS)",
  "company": "Security",
  "onelinerdescription": "Comprehensive video management system for robust client surveillance and monitoring.",
  "image": video_management_system,
  "embed": "<iframe width=\"914\" height=\"514\" src=\"https://www.youtube.com/embed/i_VwMbqb5wQ\" title=\"SixthSensAI VMS Demo\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
        "description": (
    <div>
      <h3>System Overview:</h3>
      <p>
        Our Video Management System (VMS) is a sophisticated software solution tailored for robust surveillance and monitoring of client premises. It offers comprehensive features for efficient video handling and management.
      </p>
      <br /><br />

      <h3>Key Features:</h3>
      <ul>
        <li>
          <p>Video Surveillance: Enables seamless monitoring and recording of video streams from multiple client locations.</p>
        </li>
        <li>
          <p>Centralized Management: Provides a centralized platform for managing and accessing video feeds, ensuring easy navigation and control.</p>
        </li>
        <li>
          <p>Scalability: Designed to scale effortlessly to accommodate varying numbers of clients and video feeds.</p>
        </li>
        <li>
          <p>Customized Access: Offers role-based access control, allowing designated personnel to access specific client feeds based on permissions.</p>
        </li>
        <li>
          <p>Event Detection and Alerts: Incorporates intelligent algorithms for event detection and triggers alerts for unusual activities or predefined events.</p>
        </li>
        <li>
          <p>Remote Accessibility: Facilitates remote access for authorized users to view live or recorded feeds, ensuring accessibility from anywhere.</p>
        </li>
      </ul>
      <br /><br />

      <h3>User Interface:</h3>
      <p>
        The user interface is intuitively designed, providing a user-friendly experience for navigating between client locations, accessing feeds, and managing surveillance settings.
      </p>
      <br /><br />

      <h3>Application:</h3>
      <p>
        Our VMS finds application in various sectors, including commercial establishments, educational institutions, and residential complexes, ensuring comprehensive surveillance and security measures.
      </p>
    </div>
  ),
  "category": "Video",
  "reachoutmessage": "For VMS Demo or Inquiries, Please Reach Out to shubham@sixthsens.ai"
},
  {
  "id": "data-scraper",
  "name": "Data Scraper",
  "company": "Automation",
  "onelinerdescription": "A versatile web scraping tool for collecting diverse data from the internet.",
  "image": data_scraper,
        "embed": "<iframe width=\"914\" height=\"514\" src=\"https://www.youtube.com/embed/kE9mZslZPKw\" title=\"Resume Scraping Demo\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
  "description": (
    <div>
      <h3><strong>Module Overview:</strong></h3>
      <p>
        The <strong>Data Scraper</strong> module is a versatile and adaptable web scraping tool designed to collect diverse data from the internet across various sources and formats.
      </p>
      <br /><br />

      <h3><strong>Features:</strong></h3>
      <ul>
        <li>
          <p><strong>Dynamic Scraping Capabilities:</strong> Enables the extraction of data from a wide range of websites, adjusting to varying structures and formats.</p>
        </li>
        <li>
          <p><strong>Customizable Configuration:</strong> Allows users to define and configure scraping parameters tailored to specific data sources or formats.</p>
        </li>
        <li>
          <p><strong>Scalability:</strong> Handles large-scale data scraping tasks efficiently, facilitating the collection of extensive datasets.</p>
        </li>
        <li>
          <p><strong>Robust Error Handling:</strong> Implements error-handling mechanisms to ensure reliability and stability during the scraping process.</p>
        </li>
        <li>
          <p><strong>Output Formats:</strong> Offers flexibility in output formats, allowing data extraction in various structured formats like JSON, CSV, or XML.</p>
        </li>
        <li>
          <p><strong>Scheduling and Automation:</strong> Supports scheduling and automation for recurring data scraping tasks with defined intervals.</p>
        </li>
      </ul>
      <br /><br />

      <h3><strong>Usage:</strong></h3>
      <p>
        The <strong>Data Scraper</strong> finds application across industries for data analysis, market research, competitive intelligence, and more, providing a rich source of diverse and relevant information.
      </p>
      <p>
        <strong>Note:</strong> Data can be extracted in any format, including PDFs, text, images, audio, video, tables, and other conceivable formats.
      </p>
    </div>
  ),
  "category": "Text",
  "reachoutmessage": "For Data Scraper Demo or Inquiries, Please Reach Out to shubham@sixthsens.ai"
},
  {
  "id": "data-parser",
  "name": "Data Parser",
  "company": "Automation",
  "onelinerdescription": "A versatile tool for converting unstructured data into structured, readable formats.",
  "image": data_parser,
        "embed": "<iframe width=\"914\" height=\"514\" src=\"https://www.youtube.com/embed/4q-HhUiMrKM\" title=\"Resume Data Parser Demo\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
  "description": (
    <div>
      <h3><strong>Module Overview:</strong></h3>
      <p>
        The <strong>Data Parser</strong> module is a versatile tool designed to convert unstructured, raw, or disorganized data into structured, easily readable, and organized formats.
      </p>
      <br /><br />

      <h3><strong>Features:</strong></h3>
      <ul>
        <li>
          <p><strong>Text Parsing:</strong> Transforms unstructured text or documents into organized content, extracting key information.</p>
        </li>
        <li>
          <p><strong>Image Parsing:</strong> Extracts relevant data from images or scanned documents using Optical Character Recognition (OCR) technology.</p>
        </li>
        <li>
          <p><strong>Audio & Video Parsing:</strong> Converts spoken or visual content into transcribed text or structured data.</p>
        </li>
        <li>
          <p><strong>Table Parsing:</strong> Parses tabular data from diverse sources, organizing it into structured formats.</p>
        </li>
        <li>
          <p><strong>Customization:</strong> Allows customization of parsing algorithms and rules based on data source characteristics.</p>
        </li>
        <li>
          <p><strong>Error Handling & Validation:</strong> Implements validation checks and error handling to ensure accuracy in parsing results.</p>
        </li>
      </ul>
      <br /><br />

      <h3><strong>Usage:</strong></h3>
      <p>
        The <strong>Data Parser</strong> module finds utility across industries, facilitating data transformation, analysis, and integration into various systems and applications.
      </p>
    </div>
  ),
  "category": "Text",
        "link": "https://sixthsens-resume-parsing.streamlit.app/"
},
  {
  "id": "forecast",
  "name": "Sales Forecasting and Analysis",
  "company": "Supply Chain",
  "onelinerdescription": "A machine learning-based forecasting application that trains on provided data and forecasts for a year. Also extracts insights from the data",
  "image": sales_forecasting,
  "embed": "<iframe width=\"956\" height=\"514\" src=\"https://www.youtube.com/embed/gBBHfZqIe24\" title=\"SixthSens Supply Chain Sales Forecast and Analysis App\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
  "description": (
    <div>
      <h3><strong>Module Overview:</strong></h3>
      <p>
        The <strong> Sales Forecast and Analysis</strong> module is a machine learning-based forecasting application that trains on provided data and gives forecasts for a year while also extracting valuable insights from the data.
      </p>
      <br /><br />

      <h3><strong>Features:</strong></h3>
      <ul>
        <li>
<p><strong>Data Upload:</strong> Effortlessly upload your sales data in CSV format.</p>
        </li>
        <li>
<p><strong>Data Insights:</strong> Explore the fundamental aspects of your sales data and a sneak peek of the uploaded information. This app is designed to give you a snapshot of your data, setting the stage for deeper analysis.</p>
        </li>
        <li>
          <p><strong>Sales Visualization:</strong> Visualize your sales data dynamically with interactive plots. The app creates engaging visualizations, allowing you to compare predictions and actual sales over time. Dive into trends, patterns, and performance metrics effortlessly. </p>
        </li>
        <li>
          <p><strong>Sales Forecast:</strong> Beyond the provided features, this app also allows you to forecast sales for the upcoming months. The app utilizes the machine-learning-based model to predict future sales based on historical data. The model is trained on the uploaded data and can be used to forecast sales for the next 12 months.</p>
        </li>
        <li>
          <p><strong>Scalability & Performance:</strong> Ensures efficient performance and scalability to handle large datasets and diverse recommendation scenarios.</p>
        </li>
      </ul>
      <br /><br />

      <h3><strong>Usage:</strong></h3>
      <p>
        The <strong>Sales Forecast and Analysis</strong> module finds applications in Retail, e-Commerce, Manufacturing, Consumer Goods, and various Supply Chain driven systems, enhancing efficiency and reducing wastage.
      </p>
    </div>
  ),
  "category": "Text",
  "link": "https://sales-analysis-app.streamlit.app/"
},
  {
  "id": "recommendation",
  "name": "Recommendation System",
  "company": "Automation",
  "onelinerdescription": "A machine learning-based recommendation system for collaborative, content-based, and hybrid recommendations.",
  "image": recommendation_system,
  "embed": "<iframe width=\"914\" height=\"514\" src=\"https://www.youtube.com/embed/YOvBox14R-c\" title=\"SixthSens Resume Recommendation For Jobs Demo\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
  "description": (
    <div>
      <h3><strong>Module Overview:</strong></h3>
      <p>
        The <strong>Recommendation Engine</strong> module is a machine learning-powered system designed to provide collaborative, content-based, and hybrid recommendations across various domains.
      </p>
      <br /><br />

      <h3><strong>Features:</strong></h3>
      <ul>
        <li>
          <p><strong>Collaborative Filtering:</strong> Generates recommendations based on user behavior, preferences, and similarity with other users.</p>
        </li>
        <li>
          <p><strong>Content-Based Filtering:</strong> Recommends items based on their attributes and similarities to items previously liked or interacted with by users.</p>
        </li>
        <li>
          <p><strong>Hybrid Recommendations:</strong> Combines collaborative and content-based filtering to provide more accurate and diverse suggestions.</p>
        </li>
        <li>
          <p><strong>Machine Learning Models:</strong> Utilizes advanced algorithms and models for recommendation accuracy and scalability.</p>
        </li>
        <li>
          <p><strong>Personalization:</strong> Offers personalized recommendations to users based on their historical interactions and preferences.</p>
        </li>
        <li>
          <p><strong>Scalability & Performance:</strong> Ensures efficient performance and scalability to handle large datasets and diverse recommendation scenarios.</p>
        </li>
      </ul>
      <br /><br />

      <h3><strong>Usage:</strong></h3>
      <p>
        The <strong>Recommendation Engine</strong> module finds applications in e-commerce, content streaming, social platforms, and various recommendation-driven systems, enhancing user experience and engagement.
      </p>
    </div>
  ),
  "category": "Text",
  "reachoutmessage": "For Recommendation System Demo or Inquiries, Please Reach Out to shubham@sixthsens.ai"
},
  {
    "id": "VQA",
    "name": "Visual Question Answering System",
    "company": "Education",
      "onelinerdescription": "Bridging computer vision and natural language processing for interactive AI responses based on visual and textual input.",
    "image": visual_question_answering,
"description": (
    <div>
      <h3>Project Overview:</h3><br />
      <p>
        Visual Question Answering (VQA) is an innovative solution that bridges the gap between computer vision and natural language processing. This system empowers machines to answer questions based on visual content by combining advanced algorithms from both domains. It takes an image and a natural language question as input, extracts relevant features from the image using computer vision techniques, comprehends and interprets the question using natural language processing, and then generates meaningful answers.
      </p>
      <br /><br />
      <h3>Key Features:</h3><br />
      <ul>
        <li>
          <p>Multi-Modal Understanding: The VQA system demonstrates the capability to understand and reason about visual content and textual questions simultaneously, allowing for a more comprehensive understanding of the query.</p>
        </li>
        <li>
          <p>Image and Text Fusion: It effectively combines image features and natural language context to generate accurate and contextually relevant answers to questions.</p>
        </li>
        <li>
          <p>Adaptability: The system adapts to a wide range of question types and visual content, making it a versatile tool for applications in domains like image analysis, virtual assistants, and content recommendation.</p>
        </li>
        <li>
          <p>Enhanced Human-Machine Interaction: VQA fosters interactive and intuitive communication between humans and AI systems, improving user experiences and expanding AI applications.</p>
        </li>
      </ul>
      <br /><br />
      <h3>Use Cases:</h3><br />
      <p>
        Visual Question Answering (VQA) finds applications in diverse fields, including image search, content recommendation, virtual assistants, and accessibility technologies. It enriches human-AI interactions and opens new possibilities for leveraging visual and textual information effectively.
      </p>
    </div>
  ),
      "category": "Image",
    "embed": "<iframe width=\"930\" height=\"523\" src=\"https://www.youtube.com/embed/7-e_eSPI5MM\" title=\"Visual Question Answering Demo\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
},
  {
  "id": "drowsiness",
  "name": "Drowsiness Detection System",
  "company": "Transport",
  "onelinerdescription": "Using computer vision to detect driver drowsiness and prevent accidents on the road.",
  "image": drowsiness_detection,
  "description": (
    <div>
      <h3>Project Overview:</h3><br />
      <p>
        The Drowsiness Detection System is a critical safety feature designed for vehicles to mitigate the risks associated with driver fatigue and drowsiness. Leveraging advanced computer vision techniques, the system monitors the driver's behavior in real-time and issues alerts when signs of drowsiness are detected.
      </p>
      <br /><br />
      <h3>Key Features:</h3><br />
      <ul>
        <li>
          <p>Real-time Monitoring: The system continuously monitors the driver's facial expressions, eye movements, and head position to assess their level of alertness.</p>
        </li>
        <li>
          <p>Drowsiness Detection Algorithms: Utilizing machine learning algorithms, the system can recognize patterns associated with drowsiness, such as frequent blinking, yawning, and changes in head posture.</p>
        </li>
        <li>
          <p>Immediate Alerts: When signs of drowsiness are identified, the system issues immediate alerts to the driver, such as visual warnings, audible alarms, or haptic feedback to prevent potential accidents.</p>
        </li>
        <li>
          <p>Adaptive Sensitivity: The sensitivity of the drowsiness detection can be adjusted based on driving conditions, time of day, and individual driver preferences.</p>
        </li>
      </ul>
      <br /><br />
      <h3>Benefits:</h3><br />
      <p>
        The Drowsiness Detection System contributes significantly to road safety by preventing accidents caused by driver fatigue. It enhances the overall driving experience and reduces the likelihood of accidents due to impaired alertness.
      </p>
    </div>
  ),
  "category": "Video",
    "embed": "<iframe width=\"930\" height=\"523\" src=\"https://www.youtube.com/embed/PJXt-w9txEA\" title=\"Drowsiness Detection Demo\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>",
  "link": "https://drowsiness-detection-sixthsens.streamlit.app/"
}

  //   {
//     "id": "FaceRecognition",
//     "name": "Face Recognition",
//     "company": "Security",
//       "onelinerdescription": "State-of-the-art facial recognition system for precise identification, access control, and streamlined identity verification.",
//
//     "image": "https://t3.ftcdn.net/jpg/04/08/79/14/360_F_408791486_sc9aKN6ATz06tiSYIC4VfwaSX7ErPnP7.jpg",
// "description": (
//     <div>
//       <h3>Project Overview:</h3><br />
//       <p>
//         Face Recognition Security Solution represents the forefront of security technology. This cutting-edge system identifies individuals with remarkable precision by analyzing their unique facial features. It offers versatile applications in security, ranging from access control to surveillance, significantly enhancing safety and streamlining identity verification processes.
//       </p>
//       <br /><br />
//       <h3>Key Features:</h3><br />
//       <ul>
//         <li>
//           <p>Precision Identification: The project employs state-of-the-art facial recognition algorithms to accurately identify individuals based on their facial characteristics, ensuring access control and surveillance with unmatched precision.</p>
//         </li>
//         <li>
//           <p>Security Enhancement: It enhances security by preventing unauthorized access to restricted areas, securing sensitive data, and protecting facilities from potential threats, making it an invaluable asset for various sectors.</p>
//         </li>
//         <li>
//           <p>Streamlined Identity Verification: The technology streamlines identity verification processes, reducing the need for physical access cards or passwords, making access control more convenient and secure.</p>
//         </li>
//         <li>
//           <p>Adaptive Capabilities: The system adapts to varying lighting conditions, poses, and facial expressions, ensuring reliable recognition under diverse circumstances.</p>
//         </li>
//       </ul>
//       <br /><br />
//       <h3>Use Cases:</h3><br />
//       <p>
//         Face Recognition Security Solution is deployed in a range of sectors, including commercial, governmental, and residential applications. It is instrumental in access control, attendance management, visitor verification, and surveillance systems, providing a new level of security and convenience.
//       </p>
//     </div>
//   ),
//       "category": "Image",
//     "embed": "<h3>Video Demo Coming Soon!</h3>",
//     "link": "https://example.com/this-site-is-currently-under-development/"
//   },
];

const AppContext = createContext();

const initialState = {
  isLoading: false,
  isError: false,
  products: [],
  featureProducts: [],
  isSingleLoading: false,
  singleProduct: {},
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const getProducts = async () => {
    dispatch({ type: "SET_LOADING" });
    try {
      const products = API;
      dispatch({ type: "SET_API_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const getSingleProduct = async (id) => {
      dispatch({ type: "SET_SINGLE_LOADING" });
      try {
        const singleProduct = API.find((product) => product.id === id);
        dispatch({ type: "SET_SINGLE_PRODUCT", payload: singleProduct });
      } catch (error) {
        dispatch({ type: "SET_SINGLE_ERROR" });
      }
  };

  useEffect(() => {
    getProducts(API);
  }, []);

  return (
    <AppContext.Provider value={{ ...state, getSingleProduct }}>
      {children}
    </AppContext.Provider>
  );
};

// custom hooks
const useProductContext = () => {
  return useContext(AppContext);
};

export { AppProvider, AppContext, useProductContext };
