// MainPage.js
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FilterBox from './FilterBox';
import ProductCard from './ProductCard';
import Sort from './Sort';
import ProductList from './ProductList';
import FilterSection from './FilterSection';


const MainPage = () => {

  return (
    <Container style={{"marginTop":"150px", "marginBottom":"100px"}}>
      <Row>
        <Col lg={3}>
          <FilterSection />
        </Col>
        <Col lg={9}>
          <Row>
            <Sort />
          </Row>
          <Row>
            <ProductList />
          </Row>
          
        </Col>
      </Row>
    </Container>
  );
};

export default MainPage;
