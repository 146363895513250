// App.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import MainPage from './MainPage';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import SingleProduct from './SingleProduct';

function App() {
  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <Header />
        <Routes>
          <Route path='/' element={<MainPage />}>
          </Route>
          <Route path='/singleProduct/:id' element={<SingleProduct />}>
          </Route>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
