/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import './SingleProduct.css';
import { useParams } from "react-router-dom";
import { useProductContext } from "./context/product_context";
import { FiMessageSquare } from 'react-icons/fi'; // Import the floating icon
import 'bootstrap/dist/css/bootstrap.css';

const SingleProduct = () => {
    const { getSingleProduct, isSingleLoading, singleProduct } =
        useProductContext();
    const [showContactForm, setShowContactForm] = useState(false); // State for managing form visibility

    const { id } = useParams();

    const {
        name,
        company,
        category,
        description,
        embed,
        link,
        reachoutmessage,
    } = singleProduct;

    useEffect(() => {
        getSingleProduct(id);
    }, [id, getSingleProduct]);

    const toggleContactForm = () => {
        setShowContactForm((prev) => !prev);
    };

    if (isSingleLoading) {
        return <div className="page_loading">Loading.....</div>;
    }

    return (
        <div style={{ marginTop: "80px",padding:"30px"}}>
            <div className="single-container">
                <h1 className="text-center">{name}</h1>
                <div className="row">
                    <div className="col-lg-6 md-12 single-product-data">
                        <p>{description}</p>
                    </div>

                    <div className="col-lg-6 md-12 single-product-media">
                        <div className="single-product-video">
                                <div dangerouslySetInnerHTML={{ __html: embed }} />
                        </div>
                        <div>
                            <div>
                                Model Type: <span>{category}</span>
                            </div>
                            <div>
                                Industry Sector: <span>{company}</span>
                            </div>
                            {link && (
                                <div className="single-try-yourself">
                                    <button className="single-btn" onClick={() => window.open(link, "_blank")}>
                                        Live Project
                                    </button>
                                </div>
                            )}
                            <div>
                                <span>{reachoutmessage}</span>
                            </div>
                        </div>
                    </div>
                </div>
                
                <FiMessageSquare size={20} className="single-fi-message-square" onClick={toggleContactForm}> Reach Out To us </FiMessageSquare>

                {showContactForm && (
                    <div>
                        <div className="single-contact-form">
                            <br />
                            <form
                                action="https://formspree.io/f/moqobbry"
                                method="POST"
                                className="single-contact-inputs"
                            >
                                <input
                                    type="text"
                                    placeholder="Your Name"
                                    name="Name"
                                    required
                                    autoComplete="off"
                                />

                                <input
                                    type="email"
                                    name="Email"
                                    placeholder="Your Business Email"
                                    autoComplete="off"
                                    required
                                />
                                <input
                                    type="tel"
                                    name="PhoneNumber"
                                    placeholder="Your Contact Number (Optional)"
                                    autoComplete="off"
                                />
                                <textarea
                                    name="Message"
                                    cols="30"
                                    rows="5"
                                    required
                                    autoComplete="off"
                                    defaultValue={name ? `I am interested in ${name}.` : ''}
                                />

                                <input type="submit" value="Send" />
                                <button type="button" className="btn btn-success" onClick={toggleContactForm}>Close</button>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SingleProduct;
