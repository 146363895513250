import React from "react";
import ProductCard from "./ProductCard";
import './GridView.css';

const GridView = ({ products }) => {
  
  return (
    <div className="section">
      <div className="container grid grid-three-column">
        {products.map((curElem) => {
          return <ProductCard key={curElem.id} {...curElem} />;
        })}
      </div>
    </div>
  );
};

export default GridView;
