import React from "react";
import { BsFillGridFill, BsList } from "react-icons/bs";
import { useFilterContext } from "./context/filter_context";
import 'bootstrap/dist/css/bootstrap.css';
import './Sort.css'

const Sort = () => {
  const { filter_products, grid_view, setGridView, setListView, sorting } =
    useFilterContext();

  return (
    <div className="sort-section">
      {/* 1st column  */}
      <div className="sorting-list--grid">
        <button
          className={grid_view ? "active btn btn-primary" : "btn"}
          onClick={setGridView}>
          <BsFillGridFill className="icon" />
        </button>

        <button
          className={!grid_view ? "active btn btn-primary" : "btn"}
          onClick={setListView}>
          <BsList className="icon" />
        </button>
      </div>
      
      {/* 2nd column  */}
      <div className="product-data">
        <p>{`${filter_products.length} Products Available`}</p>
      </div>

      {/* 3rd column  */}
      <div className="sort-selection">
        <form action="#">
          <label htmlFor="sort"></label>
          <select
            name="sort"
            id="sort"
            className="sort-selection--style"
            onClick={sorting}>
            <option value="a-z">Name(a-z)</option>
            <option value="z-a">Name(z-a)</option>
          </select>
        </form>
      </div>
    </div>
  );
};


export default Sort;
