import { useFilterContext } from "./context/filter_context";
import 'bootstrap/dist/css/bootstrap.css';
import './FilterSection.css'

const FilterSection = () => {
  const {
    filters: { text, category },
    updateFilterValue,
    all_products,
    clearFilters,
  } = useFilterContext();

  // get the unique values of each property
  const getUniqueData = (data, attr) => {
    let newVal = data.map((curElem) => {
      return curElem[attr];
    });

    return (newVal = ["all", ...new Set(newVal)]);
  };

  const categoryData = getUniqueData(all_products, "category");
  const companyData = getUniqueData(all_products, "company");


  return (
    <div className="filter-section">
      <div className="filter-search">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="text"
            name="text"
            placeholder="Search"
            value={text}
            onChange={updateFilterValue}
          />
        </form>
      </div>

      <div className="filter-category">
        <h3>Model Type</h3>
        <div className="model-type">
          {categoryData.map((curElem, index) => {
            if (curElem === "all") return null;
            return (
              <div className="model-item" key={index}>
                <label htmlFor={`category-${index}`}>{curElem}</label>
                <input
                  type="checkbox"
                  id={`category-${index}`}
                  name="category"
                  value={curElem}
                  checked={category.includes(curElem)}
                  onChange={updateFilterValue}
                />
              </div>
            );
          })}

        </div>
      </div>

      <div className="filter-company">
        <h3>Industry Sector</h3>

        <form action="#">
          <select
            name="company"
            id="company"
            className="filter-company--select"
            onClick={updateFilterValue}>
            {companyData.map((curElem, index) => {
              return (
                <option key={index} value={curElem} name="company">
                  {curElem}
                </option>
              );
            })}
          </select>
        </form>
      </div>
      <div className="filter-clear">
        <button className="btn btn-primary" onClick={clearFilters}>
          Clear Filters
        </button>
      </div>
    </div>
  );
};

export default FilterSection;